@import '@mch-group/design-tokens/build/scss/design_tokens';
@import '@mch-group/uikit-themes/dist/artbasel/variables/_colors.scss'; // eslint-disable-line

.fullDocumentOverlay {
  background: $color-mch-gray-100;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 7;

  .container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .centerPositioning {
      position: relative;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      .loader {
        margin-bottom: 20px;
      }
    }
  }
}