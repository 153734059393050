/* Pedro to investigate how to generate this automatically in uikit */

:root {
  --spacing-0: 0;
  --spacing-1: 2px;
  --spacing-2: 4px;
  --spacing-3: 8px;
  --spacing-4: 12px;
  --spacing-5: 16px;
  --spacing-6: 20px;
  --spacing-7: 24px;
  --spacing-8: 32px;
  --spacing-9: 40px;
  --spacing-10: 48px;
  --spacing-11: 56px;
  --spacing-12: 64px;
  --spacing-13: 72px;
  --spacing-14: 80px;
  --spacing-15: 96px;
  --spacing-16: 112px;
  --spacing-17: 120px;
}