/*! # MFP - Art Basel Theme Scoped Styles */

.prio .site-footer {
  padding: 2.5rem 0 3rem
}

@media (max-width:991px) {
  .prio .site-footer {
    padding: 0;
    margin: 0
  }
}

.prio .site-footer>.ui.container {
  overflow: hidden
}

.prio .site-footer .ui.grid>.row,
.prio .site-footer.ui.segment {
  margin: 0
}

.prio .site-footer__social {
  padding-top: 2.5rem
}

@media (min-width:768px) and (max-width:991px) {
  .prio .site-footer__social {
    padding-top: 3.25rem
  }
}

@media (max-width:767px) {
  .prio .site-footer__social {
    padding-top: 2.1875rem
  }
}

.prio .site-footer__social .icon {
  padding: 0 .875rem 0 0
}

.prio .site-footer__social .icon:last-child {
  padding-right: 0
}

@media (min-width:992px) {
  .prio .site-footer__social .icon {
    padding-right: .875rem
  }
}

@media (min-width:768px) and (max-width:991px) {
  .prio .site-footer__social .icon {
    padding-right: 1.875rem
  }
}

@media (max-width:767px) {
  .prio .site-footer__social .icon {
    padding-right: 1.25rem
  }
}

@media (max-width:991px) {
  .prio .site-footer__social {
    order: 1
  }
}

@media (min-width:768px) and (max-width:991px) {
  .prio .site-footer__social {
    padding-top: 1.875rem
  }
}

@media (max-width:767px) {
  .prio .site-footer__social {
    padding-top: 1.25rem
  }
}

.prio .site-footer__copyright {
  padding-top: 2.5rem
}

@media (max-width:991px) {
  .prio .site-footer__copyright {
    padding-top: 3.75rem
  }
}

@media (max-width:767px) {
  .prio .site-footer__copyright {
    padding-top: 2.5625rem
  }
}

.prio .site-footer__copyright>a {
  margin-left: .9375rem
}

@media (max-width:991px) {
  .prio .site-footer__copyright>a {
    margin-left: auto
  }
}

.prio .site-footer__copyright>a svg {
  width: 3.9375rem;
  height: 1.875rem
}

@media (max-width:991px) {
  .prio .site-footer__copyright>a svg {
    width: 5.125rem;
    height: 2.0625rem
  }
}

@media (max-width:767px) {
  .prio .site-footer__copyright>a svg {
    width: 3.4375rem;
    height: 1.375rem
  }
}

.prio .site-footer__copyright span {
  line-height: 1.25rem;
  display: inline-block;
  position: relative;
  top: -.125rem
}

@media (max-width:991px) {
  .prio .site-footer__copyright {
    order: 4
  }
}

@media (max-width:767px) {
  .prio .site-footer__copyright {
    padding-bottom: 1.375rem
  }
}

/*! # MFP - Common App Styles */

@media only screen and (max-width:767px) {

  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="mobile hidden"],
  [class*="or lower hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile) {
    display: none !important
  }
}

@media only screen and (min-width:768px) and (max-width:991px) {

  [class*="computer only"]:not(.tablet),
  [class*="large screen only"]:not(.tablet),
  [class*="mobile only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile),
  [class*="tablet hidden"],
  [class*="widescreen only"]:not(.tablet) {
    display: none !important
  }
}

@media only screen and (min-width:992px) and (max-width:1199px) {

  [class*="computer hidden"],
  [class*="large screen only"]:not(.computer),
  [class*="mobile only"]:not(.computer),
  [class*="or lower hidden"]:not(.tablet):not(.mobile),
  [class*="tablet only"]:not(.computer),
  [class*="widescreen only"]:not(.computer) {
    display: none !important
  }
}

@media only screen and (min-width:1200px) and (max-width:1919px) {

  [class*="computer only"]:not([class*="large screen"]),
  [class*="large screen hidden"],
  [class*="mobile only"]:not([class*="large screen"]),
  [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile),
  [class*="tablet only"]:not([class*="large screen"]),
  [class*="widescreen only"]:not([class*="large screen"]) {
    display: none !important
  }
}

@media only screen and (min-width:1920px) {

  [class*="computer only"]:not([class*=widescreen]),
  [class*="large screen only"]:not([class*=widescreen]),
  [class*="mobile only"]:not([class*=widescreen]),
  [class*="tablet only"]:not([class*=widescreen]),
  [class*="widescreen hidden"],
  [class*="widescreen or lower hidden"] {
    display: none !important
  }
}

body .soft-text,
html .soft-text {
  color: #bdbdbd
}

.modals .label.label-2,
.prio .label.label-2 {
  color: #757575
}

.modals .label,
.prio .label {
  font-family: Art Basel Text, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  font-weight: 100
}

.modals .label.label-1,
.prio .label.label-1 {
  font-weight: 700;
  font-size: .9375rem;
  line-height: 1.5rem
}

.modals .label.label-2,
.prio .label.label-2 {
  font-size: .9375rem;
  line-height: 1.5rem
}

.modals .label.label-3,
.prio .label.label-3 {
  font-size: .875rem;
  line-height: 1rem
}

.modals .label.label-4,
.prio .label.label-4 {
  font-weight: 700;
  font-size: .8125rem;
  line-height: 1rem
}

.modals .label.label-5,
.prio .label.label-5 {
  font-size: 2.25rem;
  line-height: 2.5rem
}

.modals .label.label-6,
.prio .label.label-6 {
  font-size: 1.5rem;
  line-height: 2rem
}

.modals .label.label-7,
.prio .label.label-7 {
  font-size: .75rem;
  line-height: 1.125rem
}

.modals .label.label-8,
.prio .label.label-8 {
  font-size: .9375rem;
  line-height: 1.25rem
}

@media (min-width:768px) and (max-width:991px) {

  .modals .label.label-8,
  .prio .label.label-8 {
    font-size: 1.3125rem;
    line-height: 1.5rem
  }
}

@media (max-width:767px) {

  .modals .label.label-8,
  .prio .label.label-8 {
    font-size: .875rem;
    line-height: 1rem
  }
}

.hidden-chars {
  letter-spacing: .25rem
}

body .main-text,
html .main-text {
  font-size: 1.125rem;
  line-height: 1.75rem
}

@media (min-width:768px) and (max-width:991px) {

  body .main-text,
  html .main-text {
    font-size: 1.6875rem;
    line-height: 2.25rem
  }
}

@media (max-width:767px) {

  body .main-text,
  html .main-text {
    font-size: 1.125rem;
    line-height: 1.5rem
  }
}

body .main-text-1,
html .main-text-1 {
  font-size: 1.125rem;
  line-height: 1.5rem
}

@media (min-width:768px) and (max-width:991px) {

  body .main-text-1,
  html .main-text-1 {
    font-size: 1.6875rem;
    line-height: 2.25rem
  }
}

@media (max-width:767px) {

  body .main-text-1,
  html .main-text-1 {
    font-size: 1.125rem;
    line-height: 1.5rem
  }
}

.ui.form .field .ui.input,
.ui.form .field select {
  border-bottom: 1px solid #d0d0d0
}

.ui.form .field textarea {
  border: 1px solid #d0d0d0
}

.ui.form .field .ui.input.error {
  border-bottom-color: #c9012f
}

.ui.action.input>.ui.input {
  border-bottom: none
}

.form-error-message {
  color: #c9012f
}

.form-error-message__position {
  margin-top: 1.875rem;
  margin-bottom: -1.875rem;
  position: relative;
  z-index: 1
}

.form-error-message a {
  text-decoration: underline
}

.form-error-message a:hover {
  color: inherit
}

.ui.form .ui.action.input:not([class*="left action"])>.button:last-child {
  border-radius: 0
}

.ui.form .ui.selection.dropdown {
  border-left: none;
  border-right: none;
  border-top: none
}

.ui.form .ui.selection.dropdown.multiple {
  min-height: 2.375rem
}

.ui.form .ui.selection.dropdown .menu>.item:first-child {
  border-top: .0625rem solid grey
}

@media (min-width:768px) and (max-width:991px) {
  .ui.form .ui.selection.dropdown .menu {
    max-height: 18.75rem
  }
}

@media (max-width:767px) {
  .ui.form .ui.selection.dropdown .menu {
    max-height: 12.5rem
  }
}

.ui.form .ui.selection.dropdown>.dropdown.icon {
  display: block;
  margin: 0;
  padding: 0;
  width: 10px;
  height: 10px;
  background-size: 100%;
  top: 37%;
  text-indent: -9999px;
  opacity: 1
}

@media (min-width:768px) and (max-width:991px) {
  .ui.form .ui.selection.dropdown>.dropdown.icon {
    width: .9375rem;
    height: .9375rem
  }
}

.ui.form .ui.selection.dropdown>.dropdown.icon:before {
  content: none
}

.isDark .ui.form .ui.selection.dropdown>.text:not(.default) {
  color: #bdbdbd
}

.isDark .ui.form .ui.selection.dropdown {
  background: transparent
}

.ui.form .fit-column .ui.selection.dropdown {
  min-width: 100%
}

.ui.form .fit-column .ui.selection.dropdown .menu {
  border-top-width: .0625rem !important
}

.ui.form .fit-column .ui.selection.dropdown .menu>.item:first-child {
  border-top: none
}

.ui.form .max-content .ui.selection.dropdown .menu {
  width: auto
}

.ui.form .max-content .ui.selection.dropdown .menu .item {
  white-space: nowrap
}

.ui.form .ui.button+.ui.button {
  margin-left: 1rem
}

.ui.form .ui.checkbox .box,
.ui.form .ui.checkbox label {
  padding-left: 2.1875rem
}

.ui.form .ui.checkbox:not(.radio) input:checked~.box:after,
.ui.form .ui.checkbox:not(.radio) input:checked~label:after {
  content: "";
  position: absolute;
  left: .3125rem;
  top: -.0625rem;
  width: 1.3125rem;
  height: 1.3125rem
}

.ui.form .ui.checkbox.dark:not(.radio) input:checked~.box:after,
.ui.form .ui.checkbox.dark:not(.radio) input:checked~label:after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid #444749;
  background-color: #444749;
  top: inherit;
  left: inherit
}

.ui.form .ui.checkbox.dark:not(.radio) input:checked:focus~.box:after,
.ui.form .ui.checkbox.dark:not(.radio) input:checked:focus~label:after,
.ui.form .ui.checkbox.dark input:focus~label:before {
  border-color: #444749
}

.ui.radio.checkbox label {
  padding-top: .125rem
}

.prio .ui.radio.checkbox label:before {
  border-color: rgba(34, 36, 38, .8);
  width: 20px;
  height: 20px
}

@media (min-width:768px) and (max-width:991px) {
  .prio .ui.radio.checkbox label:before {
    width: 30px;
    height: 30px
  }
}

.prio .ui.radio.checkbox label:after {
  transform: scale(.7);
  width: 20px;
  height: 20px
}

@media (min-width:768px) and (max-width:991px) {
  .prio .ui.radio.checkbox label:after {
    width: 30px;
    height: 30px
  }
}

.prio .ui .checkbox input:checked:focus~.box:before,
.prio .ui .checkbox input:checked:focus~label:before,
.prio .ui .checkbox input:checked~label:before,
.prio .ui .checkbox input:focus~label:before {
  border-color: #444749
}

.ui.checkbox.disabled label:before {
  border: .0625rem solid rgba(40, 40, 40, .3)
}

.ui.form .field>label {
  font-weight: 700;
  color: #444749
}

@media (max-width:991px) {
  .modals.dimmer .ui.scrolling.modal {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important
  }
}

@media (min-width:768px) {
  .ui.modal {
    padding-bottom: 2.5rem
  }
}

.ui.modal .scrolling.content {
  max-height: 50vh
}

.ui.modal .content .error-list {
  margin: 2.5rem 0 1.25rem 1.25rem
}

@media (max-width:767px) {
  .ui.modal>.actions>.button {
    margin-left: 0 !important
  }
}

.ui.modal .modal-icon-close {
  position: absolute;
  top: 2.375rem;
  right: 2.375rem;
  width: 1.25rem;
  height: 1.25rem;
  fill: #444749;
  cursor: pointer;
  z-index: 1
}

@media (min-width:768px) and (max-width:991px) {
  .ui.modal .modal-icon-close {
    top: 1.5rem;
    right: 1.5rem
  }
}

@media (max-width:767px) {
  .ui.modal .modal-icon-close {
    top: 1rem;
    right: 1rem
  }
}

.ui.modal svg+.content {
  margin-top: 1.5625rem
}

.ui.modal .modal-icon-close {
  top: -3.75rem;
  right: 0;
  width: 2.5rem;
  height: 2.5rem;
  fill: #fff
}

.prio .ui.table.designation-list tr td {
  border-top: .0625rem solid #bdbdbd
}

.prio .ui.table.designation-list thead th {
  border-bottom: .125rem solid #bdbdbd
}

.prio .ui.table.no-borders tr td {
  border-top: 0
}

.prio .ui.table.no-borders tr td:first-of-type {
  padding-left: 0
}

.prio .ui.table.no-borders tr td:last-of-type {
  padding-right: 0
}

.prio .ui.table.no-borders thead th {
  border: none
}

.prio .ui.table.no-borders thead th:first-of-type {
  padding-left: 0
}

.prio .ui.table.no-borders thead th:last-of-type {
  padding-right: 0
}

.prio .ui.table.no-borders thead th.sorted,
.prio .ui.table.no-borders thead th.sorted:hover,
.prio .ui.table.no-borders thead th:hover {
  background: none
}

.prio .ui.table.designation-list tr td {
  border-top: .0625rem solid #f4f3f3
}

.prio .ui.table.designation-list thead th {
  padding-bottom: .625rem;
  border-bottom: .0625rem solid #f4f3f3
}

.prio .ui.table.designation-list tbody td .remove-designation {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer
}

.prio .ui.table.designation-list tbody td .remove-designation svg {
  width: 1rem;
  height: 1rem
}

.prio .ui.table.sortable thead th:not(.not-sortable):after {
  content: url(/static/svg/column-reorder.svg);
  display: inline-block
}

.prio .ui.table.sortable thead th.ascending:after,
.prio .ui.table.sortable thead th.descending:after {
  width: 0;
  height: 0;
  border-left: .25rem solid transparent;
  border-right: .25rem solid transparent;
  content: " ";
  margin: 0 0 .1875rem .75rem
}

.prio .ui.table.sortable thead th.descending:after {
  border-top: .25rem solid #444749
}

.prio .ui.table.sortable thead th.ascending:after {
  border-bottom: .25rem solid #444749
}

.prio .icon svg {
  width: .9375rem;
  height: .9375rem
}

@media (min-width:768px) and (max-width:991px) {
  .prio .icon svg {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem
  }
}

@media (max-width:767px) {
  .prio .icon svg {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
  }
}

.prio .icon .svg-favorite {
  fill: #fff;
  stroke: #000
}

.prio .icon.delete:before {
  content: "\f00d";
  font-style: normal;
  font-family: FontAwesome, Icons
}

.prio .icon.calendar:before {
  content: "\F133";
  font-style: normal;
  font-family: FontAwesome, Icons
}

.prio .icon.time:before {
  content: "\F017";
  font-style: normal;
  font-family: FontAwesome, Icons
}

.prio .icon.trash:before {
  content: "\F1F8";
  font-style: normal;
  font-family: FontAwesome, Icons
}

.prio .icon.trash.large:before {
  font-size: 140%
}

/*! # MFP | Base - Global App Styles and Overrides */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.site-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .3);
  overflow-x: hidden;
  transition: .5s;
  display: none
}

.site-overlay--visible {
  display: block
}

@media (max-width:991px) {
  #prio:not(.from-web-view) {
    overflow-x: hidden
  }
}

#prio .no-margin-top {
  margin-top: 0 !important;
  padding-top: 0 !important
}

.prio {
  display: flex;
  min-height: 100vh;
  flex-direction: column
}

.prio>.site-content {
  flex: 1
}

.prio img[alt]:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  content: attr(alt);
  text-indent: -600rem
}

@media (min-width:992px) {
  .prio.page-has-menu-component.page-has-video-as-title .site-modules>section:first-child[class^=VideoPlayer] {
    margin-bottom: 3.75rem
  }
}

@media (min-width:992px) {
  .prio.page-has-menu-component.page-has-video-as-title .site-header--video-player-active~.site-content .rm-page-menu-wrapper {
    top: 100vh
  }
}

@media (max-width:767px) {
  .nprogress-busy #nprogress .spinner {
    right: 50%;
    margin-right: -9px
  }
}

/*! # MFP | Art Basel - Global App Styles and Overrides */

/*! # MFP | Art Basel | override commons */

a:not(.no-hover)>svg:hover,
a>svg.active {
  fill: #1d2327
}

.prio .ui.message {
  display: flex;
  align-items: center;
  margin: 0
}

.prio .ui.message.small {
  padding: 1.1875rem 1.875rem
}

.prio .ui.message.no-bkg {
  padding: 0;
  background: none
}

.prio .ui.message.no-bkg svg {
  fill: #c9012f
}

.prio .ui.message.no-bkg p {
  color: #c9012f
}

.prio .ui.message svg {
  fill: #fff;
  height: 2.25rem;
  width: 2.25rem;
  margin: 0 1.25rem 0 0
}

.prio .ui.message p {
  line-height: 1.375rem
}

.prio img[alt]:after {
  background-color: #fff
}

.prio .boxed-text {
  border: .0625rem solid #9b9b9b;
  padding: .25rem .5rem;
  font-size: .75rem;
  color: #9b9b9b;
  margin-right: .375rem;
  margin-bottom: .375rem;
  display: inline-block;
  text-transform: uppercase
}

.prio .boxed-text.inverse {
  background-color: #444749;
  border: .0625rem solid #444749;
  color: #fff
}

.prio .favorite-icon--on {
  fill: #000
}

.prio .favorite-icon--private.favorite-icon--off {
  stroke: #bdbdbd;
  fill: #444749
}

.prio .favorite-icon--private.favorite-icon--on {
  stroke: #bdbdbd;
  fill: #bdbdbd
}

@media screen and (-ms-high-contrast:active),
screen and (-ms-high-contrast:none) {
  .prio .site-modules {
    overflow-y: hidden
  }

  .prio .site-header__logo {
    display: inline-block;
    width: 135px;
    height: 30px
  }

  .prio .site-header__search-icon {
    width: 18px;
    height: 18px;
    margin-top: 30px
  }

  .prio .site-content {
    flex-basis: auto
  }
}

.prio .site-footer.ui.vertical.segment {
  background: #1d2327
}

.prio .site-footer__copyright a,
.prio .site-footer__copyright span,
.prio .site-footer__social a,
.prio .site-footer__social span {
  color: #bdbdbd
}

.prio .site-footer__copyright svg,
.prio .site-footer__social svg {
  fill: #bdbdbd;
  width: 1.125rem;
  height: 1.125rem
}

.prio .site-footer__copyright a:hover,
.prio .site-footer__social a:hover {
  color: #fff
}

.prio .site-footer__copyright svg:hover,
.prio .site-footer__social svg:hover {
  fill: #fff
}

.prio .site-footer__social {
  font-size: .8125rem
}

.prio .site-footer__copyright {
  font-size: .8125rem;
  text-align: right;
  display: flex !important;
  align-items: center
}

@media (min-width:992px) {
  .prio .site-footer__copyright {
    justify-content: flex-end
  }
}

@media (max-width:991px) {
  .prio .site-footer__copyright {
    text-align: left;
    padding-top: 0;
    padding-bottom: 0
  }
}

@media (min-width:768px) and (max-width:991px) {
  .prio .site-footer__copyright {
    font-size: 1.25rem
  }
}