/** This CSS emulates the "UI Kit Grid and Breakpoints" behavior for all those modules that still
consume the "Semanit UI Grid and Breakpoints" */

/**
These are old semantic ui lines.
Probably can be removed.
If not, the place that consumes this CSS must be changed.
*/

/* As UI Kit does, we force Semantic UI container, to stop growing at 1536px. */
/* Below 1536px it will take full screen space */
/* We only apply this for desktop and higher, because in mobile, Semantic UI already behaves
similar to UI Kit, so better be conservative */
@media (min-width: 768px) {
  .ui.container {
    max-width: 1536px !important;
    width: 100%;
  }
}

/* Unfortunately some inner components are inheriting the paddings from the general container.
This class can be applied everywhere this happens */
@media (min-width: 768px) {
  .ui.container.no-horizontal-padding {
    padding-left: 0;
    padding-right: 0;
  }
}

/* NOTE: UI Kit (a.k.a Bootstrap) has a lot of classes that allow to define custom left/right
paddings depending on the size of the screen. MarketPlace team seems to be using repeatedly the same
spacings for each "<section />" (px-5 px-lg-8 px-md-7). These 3 pointers coming next, seem to make the job
in emulating these 3 px-5 px-lg-8 px-md-7 classes that can be seen in the MP website */

/* Simulates the class UI Kit class "px-5" */
@media (min-width: 768px) {
  .ui.container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* Some more left/right spacings, specific value for certain resolutions, as in UI Kit, emulates px-lg-8 */
@media (min-width: 1024px) {
  .ui.container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/* Some more left/right spacings, specific value for certain resolutions, as in UI Kit, emulates px-md-7 */
@media (max-width: 1023px) and (min-width: 768px) {
  .ui.container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

/* Defines the gutter spaces in the same way than UI Kit */
@media (min-width: 768px) {
  .ui.grid>.column:not(.row), .ui.grid>.row>.column {
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
  }
}

/* UI Kit also defines these variables, but I can't consume them, because
they are created differently, pointing to a different CSS element. Here I just create the
vars in the CSS pointer that will consume them */
@media (min-width: 768px) {
  .ui.grid>.column:not(.row), .ui.grid>.row>.column {
    --bs-gutter-x: 1.5rem;
  }
}

/* Same than above, but for a different resolution */
@media (min-width: 1024px) {
  .ui.grid>.column:not(.row), .ui.grid>.row>.column {
    --bs-gutter-x: 2rem;
  }
}

@media (min-width: 768px) {
  /* Compensates the changes suffered by the gutters to emulate UI Kit */
  .ui.grid {
    margin: -1rem -16px;
  }
}

/* Useful lines to uncomment, in case of debug and investigate the grid */
/*
.container {
  background: black;
}

.container > div.row > div {
  background: green;
}

.container > div.row > div > div {
  background: red;
}

.ui.container div.row > div {
  background: green;
}
*/

/*------ SEMANTIC MODALS ADAPTED TO LIVE WITH UI KIT MODALS ------*/
.ui.modal {
  height: initial;
  left: initial;
  overflow: initial;
  top: initial;
}

/*------ SEMANTIC DIMMERS ADAPTED TO LIVE WITH MEGAMENU ------*/
.ui.dimmer {
  z-index: 1020;
}

/*------ SEMANTIC TABLES ADAPTED TO LIVE WITH UI KIT TABLES ------*/
.ui.table {
  vertical-align: middle;
}

.ui.table>:not(:first-child) {
  border-top: 1px solid rgba(34,36,38,.15);
}

/** Megamenu stretches the content beyond the body container,
which creates a horizontal scroll. These two lines prevent from that
This should ideally be in UIKit.
This issue is already reported in email
This is not a recommended practice (to override UIKit styles)
*/
.navbar-parent-container {
  /* width: 100vw */
  width: unset;
}

.navbar-parent-container .megamenu-underline {
  /* width: 100vw */
  width: 100%;
}

/** The combination of 2 Frameworks SEMANTIC UI and BOOTSTRAP cause a glitch in the Navigation */
/** This block can be removed once the site is using Megamenu */
@media (min-width: 992px) {
  .site-header__primary-nav-mobile {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .site-header__page-menu-mobile {
    display: none !important;
  }
}

/**
Form elements. Using the colors of the new palette (cannot consume Tokens here).
Sad cannot use Tokens here, but this elements will eventually be moved to UIKIT. So this is temporary code.
*/
.ui.form .ui.selection.dropdown>.dropdown.icon {
  transform: scale(1.8);
  background: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cstyle type%3D%22text%2Fcss%22%3E.svg-chevron-down%7Bfill%3Anone%3Bstroke%3A%23121212%3Bstroke-miterlimit%3A10%3B%7D%3C%2Fstyle%3E%3Crect x%3D%22-1%22 y%3D%22-1%22 width%3D%2212%22 height%3D%2212%22 fill%3D%22none%22%2F%3E%3Cpolyline class%3D%22svg-chevron-down%22 points%3D%229.5 2.7 5 7.3 0.5 2.7%22 stroke%3D%22null%22%2F%3E%3C%2Fsvg%3E') no-repeat;
}

.ui.selection.dropdown {
  border: 1px solid #bfbfbf;
}

.ui.selection.active.dropdown, .ui.selection.active.dropdown .menu, .ui.selection.dropdown:hover {
  border-color: #121212;
  box-shadow: none;
}

.ui.form .field .ui.input {
  border-bottom: 1px solid #bfbfbf;
}

.ui.form .field .ui.input:hover {
  border-bottom: 1px solid #121212;
}

.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
  border-bottom: var(--bs-border-width) solid var(--bs-border-color) !important;
}

/*------ BLURRING BACKGROUND ------*/
.site-content.blur {
  filter: blur(10px) grayscale(0.7);
}

@media (max-width: 1024px) and (min-width: 769px) {
  .site-content.blur {
    filter: blur(10px) grayscale(0.7);
  }
}

/* STYLES FOR DASHBOARD */
.is-dashboard-page {
  background: var(--bs-mch-gray-200);
}

.is-dashboard-page .rm-secondary-header div .sticky {
  background-color: var(--bs-mch-white);
}