// Only add here what is used in all the webpages

// transition SUI component
.transition {
  animation-iteration-count: 1;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}

.animating.transition {
  backface-visibility: hidden;
  visibility: visible !important;
}

.loading.transition {
  position: absolute;
  top: -99999px;
  left: -99999px;
}

.hidden.transition {
  display: none;
  visibility: hidden;
}

.visible.transition {
  display: block !important;
  visibility: visible !important;
}

.looping.transition {
  animation-iteration-count: infinite;
}

// segment SUI component
.segment {
  position: relative;
  background: #FFFFFF;
  margin: 1rem 0em;
  padding: 1em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.segment:first-child {
  margin-top: 0em;
}
.segment:last-child {
  margin-bottom: 0em;
}
.segments {
  flex-direction: column;
  position: relative;
  margin: 1rem 0em;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.segments:first-child {
  margin-top: 0em;
}
.segments:last-child {
  margin-bottom: 0em;
}
.segments:not(.horizontal) > .segment:first-child {
  border-top: none;
  margin-top: 0em;
  bottom: 0px;
  margin-bottom: 0em;
  top: 0px;
}
.segments:not(.horizontal) > .segment:last-child {
  top: 0px;
  bottom: 0px;
  margin-top: 0em;
  margin-bottom: 0em;
}